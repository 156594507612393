
import { defineComponent, reactive } from "vue";
import Swiper from "./modules/swiper.vue";
import SubNav from "./modules/subNav.vue";
import Professor from "./modules/professor.vue";
import Message from "@/components/message/Message.vue";
import axios from "@/utils/axios";

export default defineComponent({
  name: "home",
  components: {
    Swiper,
    SubNav,
    Message,
    Professor,
  },
  data() {
    return {
      introduceImg: require("@/assets/1new/师资培训/2.jpg"),
      systemImg: require("@/assets/1new/师资培训/3.jpg"),
      featureImg: require("@/assets/1new/师资培训/4.jpg"),
      sortImgList: [
        require("@/assets/1new/师资培训/5.jpg"),
        require("@/assets/1new/师资培训/6.jpg"),
        require("@/assets/1new/师资培训/7.jpg"),
      ],
      authorityImg: require("@/assets/1new/师资培训/8.jpg"),
      dataImg: require("@/assets/1new/师资培训/9.jpg"),
      wonderfulImg: require("@/assets/1new/师资培训/10.jpg"),
      signUpImg: require("@/assets/1new/师资培训/11.jpg"),
      redBack: require("@/assets/1new/师资培训/师资培训红色对钩.jpg"),
      greenBack: require("@/assets/1new/师资培训/师资培训绿色.jpg"),
      greenPoint: require("@/assets/1new/师资培训/师资培训绿点.jpg"),
      whitePoint: require("@/assets/1new/师资培训/师资培训白点.jpg"),
      dateImg: require("@/assets/1new/师资培训/图片1.png"),
      state: 1,
    };
  },
  methods: {
    goDetail(id: any) {
      console.log(id);
      this.$router.push({ name: "professorDetail", params: { id: id } });
    },
    goNew(a:any){
      window.open(a)
    },
    choice(i: any) {
      console.log(i);
      this.state = i;
    },
    go(a: any) {
      var aim;
      switch (a) {
        case 0:
          aim = "senior";
          break;
        case 1:
          aim = "middle";
          break;
        case 2:
          aim = "ability";
          break;
      }
      console.log(aim);

      this.$router.push("/" + aim);
    },
  },
  setup() {
    var mess = reactive({ data: "" });
    axios
      .get("https://www.xsy985.com/api/class/class/")
      .then(function (response) {
        console.log(response.data, "资讯");
        mess.data = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    return {
      mess,
    };
  },
});
